import { t } from 'i18next';

export type StockInsightsListRequest = {
    utc_offset: number;
    ids: string[];
    project_ids: string[];
    project_outlet_ids: string[];
    sku_ids: string[];
    category_ids: string[];
    visit_ids: string[];
    start_at_lower: string;
    start_at_upper: string;
    create_at_lower: string;
    create_at_upper: string;
    check_at_lower: string;
    check_at_upper: string;
    visit_at_lower: string;
    visit_at_upper: string;
    last_sale_at_lower: string;
    last_sale_at_upper: string;
    without_visit_id: boolean;
    without_action: boolean;
    without_comment: boolean;
    pagination: {
        limit: number;
        offset: number;
    };
};

export type StockInsightItem = {
    id: string;
    created_at: Date;
    updated_at: Date;
    utc_offset: number;
    updated_by: string;
    project_id: string;
    project_outlet_id: string;
    sku_id: string;
    category_id: string;
    visit_id: string;
    number: string;
    external_alert_id: string;
    mode: string;
    error_type: string;
    current_stock: number;
    virtual_stock_quantity: number;
    loss_rur: number;
    turnover: number;
    start_at: Date;
    create_at: Date;
    check_at: Date;
    visit_at: Date;
    last_sale_at: Date;
    action: string;
    comment: string;
};

export type StockInsightsListResponse = {
    items: StockInsightItem[];
    total: number;
    limit: number;
    offset: number;
};

export const stockInsightsActions = {
    NOT_IN_MATRIX: t('alert.notInMatrix'),
    ON_SHELF: t('alert.onShelf'),
    STORE_NOT_ON_SHELF: t('alert.storeNotOnShelf'),
    NOT_ON_STOCKS: t('alert.notOnStocks'),
    VIRTUAL_STOCK: t('alert.virtualStock'),
    VIRTUAL_STOCK_APPROVED: t('alert.virtualStockApproved')
} as const;

export const stockInsightsComments = {
    ALREADY_ON_SHELF: t('alert.alreadyOnShelf'),
    UNABLE_TO_PUT: t('alert.unableToPut'),
    VIRTUAL_STOCK_RESET: t('alert.virtualStockReset'),
    VIRTUAL_STOCK_NOT_RESET: t('alert.virtualStockNotReset'),
} as const;
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoles } from '@tools/hooks/useRoles';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { permissionCheck } from '@tools/utils/permissionCheck';
import FiltersSelectors from '@redux/filters/selectors';
import SettingsSelectors from '@redux/settings/selectors';
import { Button, PhotoSlider } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import { useAppSelector } from '@tools/hooks/redux';
import { getDateGMT } from '@tools/utils/date.util';
import { getPhotos } from '@tools/utils/functions';
import { VisitFeedItem } from '@/types/visitFeed';
import { exportPhotoFiles } from '@apiFeature/exportPhotoFiles';
import { fetchTasksListV3 } from '@apiFeature/tasks/tasks';
import { fetchCheckListResult } from '@apiFeature/checkers';
import { useSnackbar } from 'notistack';
import { AssignmentRounded, RestorePageOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import styles from './VisitFeedList.module.scss';
import classNames from 'classnames';
import { convertToOriginalDate } from '@tools/utils/utcOffsetConvert';

const channel = new BroadcastChannel('visitUpdateStatus');

const VisitFeedList: FC<VisitFeedItem> = (props) => {
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const { clientPermission, isClient, isRestoredSignVisible } = useRoles();
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { branches } = useAppSelector(FiltersSelectors.filtersState);
    const { settingsAll, settingsAllUser: userSettings } = useAppSelector(SettingsSelectors.settingsState);
    const photoSliderTypeValueinSettings = settingsAll?.settings?.find(
        (s) => s.code === 'type_photo_display_feed'
    )?.setting_values?.[0]?.val;
    const isEnableTaskWebClient =
        settingsAll?.settings?.find((s) => s.code === 'is_enable_task_web_client')
            ?.setting_values?.[0]?.val === 'true';
    const isTaskCreateBlocked =
        settingsAll?.settings?.find((s) => s.code === 'is_task_create_blocked')?.setting_values?.[0]
            ?.val === 'true';
    const isTaskCreateBlockedForUser =
        userSettings?.find((s) => s.code === 'sfa_tasks_create_allowed_web')
            ?.setting_value === 'FALSE'
    const feedsMerchandiserName = settingsAll?.settings?.find(
        (s) => s.code === 'feeds_merchandiser_name'
    )?.setting_values?.[0]?.val;
    const isHideFeedsVisitDuration = settingsAll?.settings?.find(
        (s) => s.code === 'feeds_visit_duration'
    )?.setting_values?.[0]?.val === 'false';
    const isHideFeedsVisitEndTime = settingsAll?.settings?.find(
        (s) => s.code === 'feeds_visit_end_time'
    )?.setting_values?.[0]?.val === 'false';
    const isHidePhotoVisitTime = settingsAll?.settings?.find(
        (s) => s.code === 'photo_visit_time'
    )?.setting_values?.[0]?.val === 'false';

    const isHideTimeZone = userSettings?.find(
        (s) => s.code === 'feeds_visit_timezone'
    )?.setting_value === 'FALSE';

    const isHideTimeZoneCheckers = userSettings?.find(
        (s) => s.code === 'visit_check_visit_timezone'
    )?.setting_value === 'FALSE';
    const isHideVisitNumber = userSettings?.find(
        (s) => s.code === 'feeds_visit_number'
    )?.setting_value === 'FALSE';
    const isHideVisitNumberCheckers = userSettings?.find(
        (s) => s.code === 'visit_check_visit_number'
    )?.setting_value === 'FALSE';

    const [photos, setPhotos] = useState([]);
    const [photoDates, setPhotoDates] = useState({
        firstPhotoCreatedAt: '',
        lastPhotoCreatedAt: '',
    });
    const [countPhotoVisitObj, setCountPhotoVisitObj] = useState({});

    const [pendingTaskTotal, setPendingTaskTotal] = useState(0);

    const {
        id,
        user,
        project,
        number,
        isCheckers = false,
        result,
        project_outlet,
        visit_type,
        visit_fail_result,
        comment,
        utc_offset,
        start_time,
        end_time,
        manager,
        filters,
        is_ended_in_place,
        is_restored,
    } = props;
    const { last_name, first_name, roles, phone } = user || {};
    const { count_photo_visit, questionnaire_ids, task_ids, lentaAlertTask, irIcTask, irAiletTask, marsAlertTask, checkers } = result || {};
    const taskResultTags = {
        [t('questionnaires.title')]: !!(questionnaire_ids && questionnaire_ids?.length > 0),
        [t('tasks.title')]: !!(task_ids && task_ids?.length > 0),
        [t('feed.lentaAlertTask')]: !!lentaAlertTask,
        [t('feed.marsAlertTask')]: !!marsAlertTask,
        'IR Insceptor Cloud': !!irIcTask,
        'IR Aliet': !!irAiletTask,
    };
    const taskResultTagsTitles = Object.keys(taskResultTags).map((tagName) => {
        if (taskResultTags[tagName]) {
            return tagName
        } else return undefined;
    }).filter(tag => tag !== undefined);

    const getTagClassName = (tag) => {
        switch (tag) {
            case t('questionnaires.title'):
                return styles.questionnairesColor;
            case t('tasks.title'):
                return styles.tasksColor;
            case t('feed.lentaAlertTask'):
                return styles.lentaAlertTask;
            case t('feed.marsAlertTask'):
                return styles.marsAlertTask;
            case 'IR Insceptor Cloud':
                return styles.irInspectorCloud;
            case 'IR Aliet':
                return styles.irAliet;
            default:
                return '';
        }
    };

    const {
        retail,
        name,
        address,
        external_code,
        ir_outlet_code,
        branch_id,
        longitude,
        latitude,
        id: project_outlet_id,
    } = project_outlet || {};
    const { short_name, id: project_id } = project || {};

    const roleData =
        roles.length > 1
            ? roles.map((i) => i?.name + ', ')
            : roles[0]?.name
            ? roles[0]?.name + ', '
            : '';
    const merchandiserRole =
        feedsMerchandiserName === t('common.promoter')
            ? feedsMerchandiserName
            : t('common.merchandiser');
    const currentRole =
        visit_type !== 'audit' && visit_type !== 'audit_manager'
            ? merchandiserRole
            : t('common.auditor');
    const role = isClient ? `${currentRole}, ` : roleData;

    const visitTypes = {
        visit: t('feed.visit'),
        audit: t('feed.audit'),
        audit_manager: t('feed.auditManager'),
    };

    const durationStart = moment(start_time);
    const durationEnd = moment(end_time);
    const diff = durationEnd.diff(durationStart);
    const duration = moment.utc(diff).format('HH:mm:ss');
    const execution_status = 'PENDING';
    const visitEndWithoutTime = moment(end_time).format('D MMMM');
    const visitDateWithoutTime = moment(start_time).format('D MMMM');
    useEffect(() => {
        fetchFeedsPhotos({ visit_id: id, is_preview: null }).then((photos) => {
            if (photos && photos?.length > 0) {
                setPhotos(photos);

                const photosForSlider = getPhotos(photos);
                const firstPhotoCreatedAt =
                    photosForSlider?.slice(0, 1)?.pop()?.file_created_at_original || '';
                const lastPhotoCreatedAt =
                    photosForSlider?.slice(-1)?.pop()?.file_created_at_original || '';
                setPhotoDates({ firstPhotoCreatedAt, lastPhotoCreatedAt });
            }
        });
    }, [id]);

    useEffect(() => {
        if (count_photo_visit) {
            setCountPhotoVisitObj({ [id]: count_photo_visit });
        }
    }, [count_photo_visit]);

    useEffect(() => {
        if (project_outlet_id) {
            const res = {
                project_id,
                project_outlet_id,
                execution_status,
                pagination: {
                    limit: 100,
                    offset: 0,
                },
            };

            fetchTasksListV3(res)
                .then((res) => {
                    let result = { ...res };
                    const { total } = result || {};

                    setPendingTaskTotal(total);
                })
                .catch((e) => enqueueSnackbar(e?.message, { variant: 'error' }));
        }
    }, [project_outlet_id]);

    const [isVisitChecked, setIsVisitChecked] = useState({ [id]: false });
    const [checkResult, setCheckResult] = useState({ [id]: null });

    const getCheckListResult = () => {
        if (isCheckers) {
            fetchCheckListResult({
                object_id: id,
            }).then((res) => {
                const checkListResults = res?.check_list_results?.items || [];
                const [currentChecker] = checkListResults || [];
                if (currentChecker) {
                    setIsVisitChecked({ [id]: true });
                    setCheckResult({ [id]: currentChecker?.result });
                }
            });
        }
    };

    const visitUpdateStatus = ({ data }) => {
        const { visit } = data || {};
        const { id: visit_id, status } = visit || {};
        if (status === 'completed' && visit_id === id) {
            getCheckListResult();
        }
    };

    useEffect(() => {
        getCheckListResult();
    }, [id, filters]);

    useEffect(() => {
        channel.addEventListener('message', visitUpdateStatus);
        return () => {
            channel.removeEventListener('message', visitUpdateStatus);
        };
    }, []);

    const serializedFeedItem = {
        [t('common.project')]: {
            value: short_name,
        },
        [t('feed.signBoard')]: {
            value: retail,
        },
        [t('feed.shopName')]: {
            value: name,
        },
        [t('feed.branch')]: {
            value: branches?.find((branch) => branch.id === branch_id)?.name,
        },
        [t('feed.address')]: {
            value: address,
        },
        [t('feed.id')]: {
            value: external_code,
        },
        [t('feed.code')]: {
            value: ir_outlet_code,
        },
        [t('feed.visitType')]: {
            value: visitTypes[visit_type] ?? '-',
        },
        [t('feed.problem')]: {
            value: visit_fail_result?.name ?? '-',
        },
        [t('feed.comment')]: {
            value: comment ?? '-',
        },
        [t('feed.countPhotoVisit')]: {
            value: countPhotoVisitObj.hasOwnProperty(id) ? countPhotoVisitObj[id] : '0',
        },
    };
    const visitStartTimeCheck = useMemo(() => {
        if (isHideTimeZone || isHideTimeZoneCheckers) {
            return convertToOriginalDate(start_time, utc_offset);
        }
        return getDateGMT(start_time, utc_offset);
    }, [isHideTimeZone, start_time, utc_offset, isHideTimeZoneCheckers]);

    const serializedFeedTimeItem = {
        [t('feed.visitStart')]: {
            value: isHideFeedsVisitEndTime ? visitDateWithoutTime : visitStartTimeCheck,
        },
        [t('feed.visitEnd')]: {
            value: isHideTimeZone || isHideTimeZoneCheckers ? convertToOriginalDate(end_time, utc_offset) : getDateGMT(end_time, utc_offset),
        },
        [t('feed.firstPhoto')]: {
            value: isHideTimeZone || isHideTimeZoneCheckers ? convertToOriginalDate(photoDates.firstPhotoCreatedAt, utc_offset) : getDateGMT(photoDates.firstPhotoCreatedAt, utc_offset),
        },
        [t('feed.lastPhoto')]: {
            value: isHideTimeZone || isHideTimeZoneCheckers ? convertToOriginalDate(photoDates.lastPhotoCreatedAt, utc_offset) : getDateGMT(photoDates.lastPhotoCreatedAt, utc_offset),
        },
        [t('feed.timeSpend')]: {
            value: duration === 'Invalid date' ? '-' : duration,
        },
    };

    if (is_ended_in_place !== null) {
        serializedFeedTimeItem[t('visits.isEndedInPlace')] = is_ended_in_place
            ? { value: t('visits.endedIn') }
            : { value: t('visits.endedOut') };
    }

    if (manager) serializedFeedTimeItem[t('common.manager')] = { value: manager || '-' };

    if (isHideFeedsVisitDuration) {
        delete serializedFeedTimeItem[t('feed.timeSpend')];
    };

    const photoDescription = {
        [t('feed.visitDate')]: {
            value: isHidePhotoVisitTime ? visitDateWithoutTime : visitStartTimeCheck,
            isVisible: true,
        },
        [t('feed.shopName')]: {
            value: name,
        },
        [t('feed.address')]: {
            value: address,
        },
        [t('feed.coordinates')]: {
            value: latitude && longitude && `${latitude}, ${longitude}`,
            isVisible: true,
        },
    };

    const handleTaskNew = () => {
        navigate(`/feed-task/create/${id}`);
    };

    const handleTaskList = () => {
        navigate(
            `/tasks?project_id=${project_id}&external_code=${btoa(
                external_code
            )}&execution_status=${btoa(execution_status)}`
        );
    };

    const downloadImages = useCallback(async () => {
        enqueueSnackbar(t('messages.soonStartDownload'), { variant: 'success' });
        const photosForDownload = await exportPhotoFiles({
            visit_ids: [id],
            project_id: project_id,
            include_visit_photo: true,
            include_question_result: true,
        });
        const href = URL.createObjectURL(photosForDownload);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `photo_files-${id}.zip`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }, [photos]);

    const openPage = () => {
        window.open(isCheckers ? `/visit-check/${id}` : `/visit_feed/${id}`);
    };

    return (
        <>
            <div className={styles.header}>
                <div className={styles.item}>
                    {!clientPermission && (
                        <div className={styles.name}>
                            {last_name} {first_name}
                        </div>
                    )}
                    <div className={styles.role}>
                        {role} {!clientPermission ? phone : ''}
                    </div>
                    <div className={styles.number_info}>
                        {!(!isCheckers && isHideVisitNumber) && !(isCheckers && isHideVisitNumberCheckers) &&
                            <div className={''}>
                                {t('feed.visitNumber')}: {number ?? '-'}
                            </div>
                        }
                        {isRestoredSignVisible && is_restored &&
                            <Tooltip title={t('reports.reports.restoredVisit')} placement="right">
                                <RestorePageOutlined color={'error'} />
                            </Tooltip>
                        }
                    </div>
                </div>
                {isCheckers && (
                    <div className={styles.item}>
                        <div className={styles.checker_badge}>
                            <div
                                className={
                                    isVisitChecked[id]
                                        ? styles.activeChecker
                                        : styles.nonActiveChecker
                                }
                            >
                                {isVisitChecked[id] ? t('crowd.checked') : t('crowd.notChecked')}
                            </div>
                            {isVisitChecked[id] && (
                                <div className={styles.checkResult}>
                                    {`${t('feed.checkResult')}: ${checkResult[id]}`}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.tags}>
                {taskResultTagsTitles.map((tag) => (
                    <div key={tag} className={classNames(styles.tag, getTagClassName(tag))}>{tag}</div>
                )
                )}
            </div>
            <div className={styles.content}>
                <div className={styles.item}>
                    <ListMapper
                        items={permissionCheck(authRoles, serializedFeedItem)}
                        className={styles.serializedFeedItem}
                    />
                </div>
                <div className={styles.item}>
                    <ListMapper
                        items={permissionCheck(authRoles, serializedFeedTimeItem)}
                        className={styles.serializedFeedTimeItem}
                    />
                </div>
            </div>
            {photos?.length > 0 && (
                <div className={styles.slider}>
                    <PhotoSlider
                        id={id}
                        photos={photos}
                        type={photoSliderTypeValueinSettings || 'carousel'}
                        description={permissionCheck(authRoles, photoDescription)}
                        utc_offset={utc_offset}
                        countPhotoVisitObj={countPhotoVisitObj}
                        setCountPhotoVisitObj={setCountPhotoVisitObj}
                        result={result}
                        hasDeleteBtn={!clientPermission}
                    />
                </div>
            )}
            <div className={styles.footer}>
                <Button color="secondary" variant="outlined" onClick={openPage}>
                    {isCheckers
                        ? checkers
                            ? t('buttons.checkResult')
                            : t('feed.checkerReport')
                        : t('feed.checkReport')
                    }
                </Button>
                {visit_type !== 'audit' &&
                    visit_type !== 'audit_manager' &&
                    !isTaskCreateBlocked &&
                    !isTaskCreateBlockedForUser && (
                        <Button color="secondary" variant="outlined" onClick={handleTaskNew}>
                            {t('tasks.createNew')}
                        </Button>
                    )}
                {!clientPermission && !isCheckers && (
                    <Button
                        color="secondary"
                        variant="outlined"
                        disabled={photos.length === 0}
                        onClick={downloadImages}
                    >
                        {t('feed.uploadPhoto')}
                    </Button>
                )}
                {(!isTaskCreateBlocked || (isClient && isEnableTaskWebClient)) && (
                    <Button
                        className={styles.pendingTaskTotal}
                        color="secondary"
                        variant="outlined"
                        onClick={handleTaskList}
                    >
                        <AssignmentRounded /> {pendingTaskTotal}
                    </Button>
                )}
            </div>
        </>
    );
};

export default VisitFeedList;

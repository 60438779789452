import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Title, Header, BackLink, Items, Loader } from '@components';
import { fetchFeed } from '@apiFeature/feeds';
import {
    fetchQuestionnaireDetailed,
    fetchUpdateQuestionnaireQuestionResultMany,
    fetchCreateQuestionnaireQuestionResultMany,
} from '@apiFeature/questionnaires';
import { QuestionnairesDetailed } from '@types/questionnaires';
import { updateFeed } from '@apiFeature/feeds';
import { fetchRetail } from '@apiFeature/retails';
import { filesUploadList } from '@apiFeature/fileService';
import { getTaskById, getTaskReserveList, getTaskResultsList } from '@apiFeature/crowd';
import { TaskItem } from '@apiFeature/crowd/types';
import { TaskResultItem } from '@apiFeature/crowd/types';
import { Book } from '@navigation/Book';
import { sortQuestionnaires } from '@tools/utils/sorting';
import { getDateGMT } from '@tools/utils/date.util';
import FakeVisitSelectors from '@/redux/fakeVisit/selectors';
import FakeVisitAction from '@/redux/fakeVisit/actions';
import Info from './Info/Info';
import InfoCrowd from './InfoCrowd/InfoCrowd';
import Images from './Images/Images';
import Reports from './Reports/Reports';
import CheckList from './CheckList/CheckList';
import styles from './VisitDetails.module.scss';
import SettingsSelectors from '@redux/settings/selectors';
import { taskReportsList } from '@apiFeature/tasks/tasks';
import TaskInfo from './TaskInfo/TaskInfo';
import { isSettingTrue } from '@tools/utils/functions';
import LentaAlert from './LentaAlert/LentaAlert';
import { stockInsightsList } from '@apiFeature/stockInsights';
import moment from 'moment';
import { SettingsThunks } from '@redux/settings/thunk';
import { convertToOriginalDate } from '@tools/utils/utcOffsetConvert';

interface IVisitDetails {
    isChecker?: boolean;
}

export const VisitDetails: FC<IVisitDetails> = ({ isChecker }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { visitID, taskId } = useParams();
    const [searchParams] = useSearchParams();
    const reportId = searchParams.get('report');

    let linkName = '';
    if (visitID) {
        linkName = !isChecker ? 'visit_feed' : 'visit_check';
    } else if (taskId) {
        linkName = !isChecker ? 'crowd_feed' : 'crowd_check';
    }
    const link = Book[linkName];

    const [section, setSection] = useState(!reportId ? 'info' : `reports:${reportId}`);
    const [isLoading, setLoading] = useState(false);
    const [feedItem, setFeedItem] = useState({});
    const [questions, setQuestions] = useState<QuestionnairesDetailed[]>([]);
    const [taskItem, setTaskItem] = useState<TaskItem>({});
    const [taskResult, setTaskResult] = useState<TaskResultItem | null>(null);
    const [resultQuestions, setResultQuestions] = useState<QuestionnairesDetailed[]>([]);
    const [isStepsGrouped, setIsStepsGrouped] = useState<boolean>(false);
    const [isQuestionnaireNameGrouped, setIsQuestionnaireNameGrouped] = useState<boolean>(false);
    const [taskReports, setTaskReports] = useState([]);
    const [stockInsights, setStockInsights] = useState([]);

    const taskQuestionnaireId = taskItem?.questionnaire_id;
    const taskReserveId = taskResult?.task_reserve_id;

    const dispatch = useDispatch();
    const { photos } = useSelector(FakeVisitSelectors.fakeVisitSelectors);
    const { settingsAll, settingsAllUser: userSettings } = useSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const isHidePhotoVisitTime =
        settings?.find((s) => s.code === 'photo_visit_time')?.setting_values?.[0]?.val === 'false';
    const isHideTimeZone = userSettings?.find(
        (s) => s.code === 'feeds_visit_timezone'
    )?.setting_value === 'FALSE';
    const isHideTimeZoneCheckers = userSettings?.find(
        (s) => s.code === 'visit_check_visit_timezone'
    )?.setting_value === 'FALSE';
    const groupQuestionnairesWithSameName = (questionnaires) => {
        const resQuestions = questionnaires.reduce((acc, question) => {
            if (acc) {
                const sameNameQ = acc.find((item) => item.name === question.name);
                if (sameNameQ) {
                    sameNameQ.questionnaire_steps.push(...question.questionnaire_steps);
                } else {
                    acc.push(question);
                }
            }
            return acc;
        }, []);
        return resQuestions;
    };

    const handleErr = (e) => {
        setLoading(false);
        const errorMessage = e?.message || e?.data?.detail || t('messages.unknownError');
        enqueueSnackbar(errorMessage, { variant: 'error' });
    };

    const getQuestionnaireDetailed = (visit_id, questionnaire_ids) => {
        fetchQuestionnaireDetailed({
            visit_id,
            ids: questionnaire_ids || [],
        })
            .then((result) => {
                setLoading(false);
                const is_questionnaire_name_grouped = isSettingTrue(
                    'is_questionnaire_name_grouped',
                    settings
                );
                const { questionnaires } = result || {};
                const resQuestionnaires = is_questionnaire_name_grouped
                    ? groupQuestionnairesWithSameName(questionnaires)
                    : questionnaires;
                resQuestionnaires.forEach((questionnaire) => {
                    let desc_group: any = [];
                    questionnaire.questionnaire_steps.forEach((step) => {
                        const currentDesc = step.description;
                        const stepsWithCurrentDesc = questionnaire.questionnaire_steps.filter(
                            (item) => item.description === currentDesc
                        );
                        const isDescGroupAdded = desc_group.find((item) =>
                            Object.keys(item).includes(currentDesc)
                        );
                        if (stepsWithCurrentDesc.length && !isDescGroupAdded) {
                            desc_group.push({ [currentDesc]: stepsWithCurrentDesc });
                        }
                    });
                    questionnaire.desc_group = desc_group;
                });
                if (questionnaire_ids?.length > 0) {
                    const sortedRes = sortQuestionnaires(resQuestionnaires || []);
                    if (sortedRes?.length) setQuestions(sortedRes || []);
                } else {
                    setQuestions(resQuestionnaires || []);
                }
            })
            .catch((e) => handleErr(e));
    };

    useEffect(() => {
        if (isQuestionnaireNameGrouped) {
            const resQuestions = groupQuestionnairesWithSameName(questions);
            setResultQuestions(resQuestions);
        } else {
            setResultQuestions(questions);
        }
    }, [questions]);

    useEffect(() => {
        if (settingsAll) {
            const { settings } = settingsAll || {};
            const is_questionnaire_name_grouped = isSettingTrue(
                'is_questionnaire_name_grouped',
                settings
            );
            const is_step_description_grouped = isSettingTrue(
                'is_step_description_grouped',
                settings
            );
            setIsStepsGrouped(is_step_description_grouped);
            setIsQuestionnaireNameGrouped(is_questionnaire_name_grouped);
        }
    }, [settingsAll]);

    useEffect(() => {
        if (!visitID) return;

        setLoading(true);

        fetchFeed(visitID)
            .then(async (res) => {
                const { feed } = res || {};
                const { project_outlets, result } = feed || {};
                const [project_outlet] = project_outlets || [];
                const { project_retail_id, project_id } = project_outlet || {};
                const { questionnaire_ids } = result || {};
                if (project_id) {
                    dispatch(SettingsThunks.getAllSettings({ project_ids: [project_id] }));
                    dispatch(SettingsThunks.getAllUserSettings({ project_id: project_id }));
                }

                let feedItem = { ...feed };
                if (project_retail_id) {
                    await fetchRetail(project_retail_id)
                        .then((result) => {
                            feedItem = { ...feedItem, project_retail_name: result?.name || '-' };
                        })
                        .catch((e) => handleErr(e));
                }
                if (questionnaire_ids && questionnaire_ids.length > 0) {
                    await getQuestionnaireDetailed(visitID, questionnaire_ids);
                }
                setFeedItem(feedItem);

                setLoading(false);
            })
            .catch((e) => handleErr(e));

        taskReportsList({
            visit_id: visitID,
        })
            .then((result) => {
                const { task_reports } = result || {};
                if (task_reports?.length) {
                    setTaskReports(task_reports);
                }
            })
            .catch((e) => handleErr(e));

        stockInsightsList({
            visit_ids: [visitID],
        })
            .then((res) => {
                const { stock_insights } = res || {};
                const { items } = stock_insights || {};
                if (items) {
                    setStockInsights(items);
                }
            })
            .catch((e) => handleErr(e));
    }, [visitID]);

    useEffect(() => {
        if (!taskId) return;

        if (taskId) {
            setLoading(true);

            getTaskById(taskId)
                .then((task) => {
                    setTaskItem(task);
                })
                .catch((e) => handleErr(e));

            getTaskReserveList({
                task_ids: [taskId],
            }).then((taskReserveList) => {
                if (taskReserveList.length) {
                    const task_reserve_id = taskReserveList[0].id;

                    if (task_reserve_id) {
                        getTaskResultsList({ task_reserve_ids: [task_reserve_id] })
                            .then((taskResultList) => {
                                if (taskResultList && taskResultList?.length)
                                    setTaskResult(taskResultList[0]);
                            })
                            .catch((e) => handleErr(e));
                    }
                }
            });

            setLoading(false);
        }
    }, [taskId]);

    useEffect(() => {
        if (taskQuestionnaireId && taskReserveId) {
            getQuestionnaireDetailed(taskReserveId, [taskQuestionnaireId]);
        }
    }, [taskQuestionnaireId, taskReserveId]);

    const handleSaveInfo = (data) => {
        setLoading(true);

        updateFeed({ visit_id: visitID, req: data })
            .then(() => {
                setLoading(false);

                enqueueSnackbar(t('messages.visitUpdateSuccess'), { variant: 'success' });

                fetchFeed(visitID)
                    .then((res) => setFeedItem({ ...feedItem, ...res?.feed }))
                    .catch((e) => handleErr(e));
            })
            .catch((e) => handleErr(e));
    };

    const handleSaveReports = async (patch_res, post_res) => {
        setLoading(true);

        try {
            if (patch_res && patch_res?.length > 0) {
                await fetchUpdateQuestionnaireQuestionResultMany({ answers: patch_res });
            }
            if (post_res && post_res?.length > 0) {
                await fetchCreateQuestionnaireQuestionResultMany({ answers: post_res });
            }

            if (photos?.length > 0) {
                for (let file of photos) {
                    const { photo, question_id } = file || {};
                    if (photo && question_id?.length > 0) {
                        const requestForUploadFiles = {
                            file: photo,
                            objects: [
                                {
                                    generic_type: 'SFA_VISIT',
                                    object_id: question_id,
                                    type: 'sfa_question_result',
                                    visit_id: visitID,
                                },
                            ],
                        };
                        await filesUploadList(requestForUploadFiles);
                    }
                }
                dispatch(FakeVisitAction.cancelPhoto());
            }

            const { result } = feedItem || {};
            const { questionnaire_ids } = result || {};
            if (questionnaire_ids && questionnaire_ids.length > 0) {
                await getQuestionnaireDetailed(visitID, questionnaire_ids);

                enqueueSnackbar(t('messages.reportUpdateSuccess'), { variant: 'success' });
            }

            setLoading(false);
        } catch (e) {
            handleErr(e);
        }
    };

    const onClickItems = (section, id?: string) => {
        setSection(section);

        const linkNav = link === '/visit-feed' ? '/visit_feed' : link;
        let resultId: string | null = null;
        if (section.includes('tasks') && id) {
            resultId = `?tasks=${id}`;
        }
        if (section.includes('report') && id) {
            resultId = `?report=${id}`;
        }
        if (section.includes('alerts') && id) {
            resultId = `?alerts=${id}`;
        }
        navigate(`${linkNav}/${visitID || taskId}${resultId ? resultId : ''}`);
    };

    let items = [
        {
            label: t('common.info'),
            onClick: () => onClickItems('info'),
            id: 'info',
            isActive: section === 'info',
        },
        {
            label: t('common.photos'),
            onClick: () => onClickItems('images'),
            id: 'images',
            isActive: section === 'images',
        },
    ];
    if (visitID) {
        items = [
            ...items,
            {
                label: t('reports.reports.title'),
                isOpen: true,
                children: resultQuestions?.map(({ id, name }) => ({
                    label: name,
                    onClick: () => onClickItems(`reports:${id}`, id),
                    id: `reports:${id}`,
                    isActive: section === `reports:${id}`,
                })),
                id: 'reports',
            },
            {
                label: t('tasks.title'),
                isOpen: true,
                children: taskReports?.map(({ id }, index) => ({
                    label: `${t('tasks.taskItem')} ${index + 1}`,
                    onClick: () => onClickItems(`tasks:${id}`, id),
                    id: `tasks:${id}`,
                    isActive: section === `tasks:${id}`,
                })),
                id: 'tasks',
            },
        ];
    } else if (taskId) {
        items = [
            ...items,
            {
                label: t('reports.reports.title'),
                onClick: () => onClickItems('reports'),
                isActive: section === 'reports',
                id: 'reports',
            },
        ];
    }
    if (stockInsights?.length) {
        items = [
            ...items,
            {
                label: t('alert.lentaAlert'),
                onClick: () => onClickItems('alerts'),
                isActive: section === 'alerts',
                id: 'alerts',
            },
        ];
    }

    let infoProps = { isChecker };
    let InfoComponent = Info;

    let start_time: string | undefined;
    let utc_offset: number | undefined;
    let project_outlet;

    if (visitID) {
        infoProps = {
            data: feedItem,
            handleSaveInfo,
            ...infoProps,
        };

        const { project_outlets, start_time: sT, utc_offset: uO } = feedItem || {};
        const [pO] = project_outlets || [];
        start_time = sT;
        utc_offset = uO;
        project_outlet = pO;
    } else if (taskId) {
        InfoComponent = InfoCrowd;
        infoProps = {
            data: { taskItem, taskResult },
            ...infoProps,
        };

        const { start_time: sT, utc_offset: uO } = taskResult || {};
        const { project_outlet: pO } = taskItem || {};

        start_time = sT;
        utc_offset = uO;
        project_outlet = pO;
    }

    const { address, name, longitude, latitude } = project_outlet || {};
    const visitDateWithoutTime = moment(start_time).format('D MMMM YYYY');
    const visitStartTimeCheck = useMemo(() => {
        if(isHideTimeZone || isHideTimeZoneCheckers) {
            return convertToOriginalDate(start_time as string, utc_offset);
        }
        return getDateGMT(start_time, utc_offset, 'D MMMM YYYY, HH:mm:ss');
    },[isHideTimeZone, start_time, utc_offset, isHideTimeZoneCheckers])

    const photoDescription = {
        [t('feed.visitDate')]: {
            value: isHidePhotoVisitTime
                ? visitDateWithoutTime
                : visitStartTimeCheck,
            isVisible: true,
        },
        [t('feed.shopName')]: {
            value: name,
        },
        [t('feed.address')]: {
            value: address,
        },
        [t('feed.coordinates')]: {
            value: latitude && longitude && `${latitude}, ${longitude}`,
            isVisible: true,
        },
    };

    const getSection = {
        info: () => <InfoComponent {...infoProps} />,
        images: () => (
            <Images
                visit_id={visitID || taskReserveId}
                photoDescription={photoDescription}
                utc_offset={utc_offset}
            />
        ),
        reports: (id) =>
            resultQuestions &&
            resultQuestions?.length > 0 && (
                <Reports
                    key={id}
                    visit_id={visitID}
                    taskId={taskId}
                    photoDescription={photoDescription}
                    utc_offset={utc_offset}
                    question={resultQuestions?.find((e) => e.id === id)}
                    handleSaveReports={handleSaveReports}
                    isChecker={isChecker}
                    is_step_description_grouped={isStepsGrouped}
                />
            ),
        tasks: (id) =>
            taskReports &&
            taskReports?.length && (
                <TaskInfo
                    key={id}
                    task_report={taskReports.find((e) => e.id === id)}
                    visit_id={visitID}
                    setTaskReports={setTaskReports}
                />
            ),
        alerts: () =>
            stockInsights && stockInsights?.length && <LentaAlert stockInsights={stockInsights} />,
    };

    const [sectionName, id] = section.split(':');

    return (
        <div className={styles.visitDetails}>
            {isLoading && <Loader />}
            <div className={styles.nav}>
                <Header className={styles.header}>
                    <Title>
                        <BackLink link={link} />
                        {t('feed.visitInfo')}
                    </Title>
                </Header>
                <Items data={items} />
            </div>
            <div className={styles.content}>
                {Object.keys(feedItem)?.length > 0 && (
                    <>
                        {getSection[sectionName]?.(id)}
                        {isChecker && <CheckList visit={feedItem} />}
                    </>
                )}
                {Object.keys(taskItem)?.length > 0 && (
                    <>
                        {getSection[sectionName]?.(taskQuestionnaireId)}
                        {isChecker && <CheckList task={taskItem} taskResult={taskResult} />}
                    </>
                )}
            </div>
        </div>
    );
};

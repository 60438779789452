import React, { FC } from "react";
import styles from './LentaAlert.module.scss';
import { Button } from '@components';
import { useTranslation } from "react-i18next";
import { StockInsightItem } from "@apiFeature/stockInsights/types";
import LentaAlertItem from "./LentaAlertItem/LentaAlertItem";
import { useRoles } from '@tools/hooks/useRoles';
interface ILentaAlert {
    stockInsights?: StockInsightItem[];
}

const LentaAlert: FC<ILentaAlert> = ({
    stockInsights
}) => {
    const { t } = useTranslation('translation');
    const { clientPermission } = useRoles();

    return (
        <div >
            <div className={styles.header}>
                {/*the ability to edit  will be added later */}
                {!clientPermission && <Button
                    variant="outlined"
                    color="secondary"
                    disabled
                >
                    {t('buttons.edit')}
                </Button>}
            </div>
            <div className={styles.productsWrapper}>
                {stockInsights?.map(si => {
                    return (
                        <LentaAlertItem stockInsight={si} />
                    )
                })
                }
            </div>

        </div>
    )
}

export default LentaAlert
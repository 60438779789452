import React, { FC, useEffect, useState } from "react";
import styles from './LentaAlertItem.module.scss';
import { PhotoSlider } from '@components';
import ListMapper from '@components/Lists/List/ListMapper/ListMapper';
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useAppSelector } from '@tools/hooks/redux';
import { permissionCheck } from '@tools/utils/permissionCheck';
import { StockInsightItem, stockInsightsActions, stockInsightsComments } from "@apiFeature/stockInsights/types";
import { getSku } from "@apiFeature/sku";
import { fetchFeedsPhotos } from '@api/visitFeed/visitFeed.api';
import moment from "moment";

interface ILentAlertItem {
    stockInsight?: StockInsightItem;
}

const LentaAlertItem: FC<ILentAlertItem> = ({ stockInsight }) => {
    const { t } = useTranslation('translation');
    const { enqueueSnackbar } = useSnackbar();
    const authRoles = useAppSelector((state) => state?.auth?.jwtPayloadOpen?.roles);
    const [skuExternalCode, setSkuExternalCode] = useState(null);
    const [skuName, setSkuName] = useState(null);
    const [photos, setPhotos] = useState([]);
    const {
        id,
        action,
        virtualstock_qty,
        comment,
        error_type,
        current_stock,
        last_sale_at,
        sku_id
    } = stockInsight || {};

    const fields = {
        [t('alert.stockStatus')]: { value: action ? stockInsightsActions[action] : '-' },
        [t('alert.virtualStockQty')]: { value: virtualstock_qty ?? '-' },
        [t('alert.comment')]: { value: comment ? stockInsightsComments[comment] : '-' },
    }

    useEffect(() => {
        if (sku_id) {
            getSku({
                ids: [sku_id]
            }).then((res) => {
                const { skus } = res || {};
                const [sku] = skus || [];
                const { external_code, name } = sku || {};
                setSkuExternalCode(external_code);
                setSkuName(name)
            }).catch((e) => {
                const errorMessage = e?.message || e?.data?.detail || t('messages.unknownError');
                enqueueSnackbar(errorMessage, { variant: 'error' });
            })
        }
    }, [sku_id]);

    useEffect(() => {
        if (id) {
            fetchFeedsPhotos({
                generic_id: id,
                is_preview: null,
            }).then((photos) => {
                setPhotos(photos);
            })
            .catch((e) => {
                const errorMessage = e?.message || e?.data?.detail || t('messages.unknownError');
                enqueueSnackbar(errorMessage, { variant: 'error' })
            });
        }
    }, [id])

    return (
        <div className={styles.productItem}>
            <div className={styles.header}>
                <div className={styles.title}>
                    {skuExternalCode} {skuName && ' | '} {skuName}
                </div>
            </div>
            <div className={styles.pins}>
                <div className={styles.pinItem}>
                    {error_type}
                </div>
                <div className={styles.pinItem}>
                    {t('alert.currentStock')}: {current_stock}
                </div>
                <div className={styles.pinItem}>
                    {t('alert.lastSale')}: {moment(last_sale_at).format('DD.MM.yyyy, HH:mm')}
                </div>
            </div>
            <div>
                <ListMapper
                    items={permissionCheck(authRoles, fields)}
                    className={styles.serializedFeedItem}
                />

            </div>
            {photos?.length > 0 && (
                <PhotoSlider
                    photos={photos}
                    type="list"
                    isSmall
                />
            )}
        </div>
    )
}

export default LentaAlertItem;
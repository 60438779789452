import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { TextareaAutosize } from '@components';
import { useAppDispatch } from '@tools/hooks/redux';
import CheckerSelectors from '@redux/checker/selector';
import CheckerAction from '@redux/checker/action';
import { checkFields } from './fields/CheckFields';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FeedsThunks } from '@redux/feeds/thunk';
import { CheckerThunk } from '@redux/checker/thunk';
import { fetchFeed } from '@apiFeature/feeds';
import { Visit } from '@types/visits';
import { TaskItem, TaskResultItem } from '@apiFeature/crowd/types';
import { updateTaskResult } from '@apiFeature/crowd';
import { useTranslation } from 'react-i18next';
import SettingsSelectors from '@redux/settings/selectors';

const channel = new BroadcastChannel('visitUpdateStatus');

interface CheckersFormsProps {
    fieldItem?: any;
    visit?: Visit;
    task?: TaskItem;
    taskResult?: TaskResultItem;
}

const CheckersForms: FC<CheckersFormsProps> = ({ fieldItem, visit, task, taskResult }) => {
    const { t } = useTranslation('translation');
    const formMethod = useForm();
    const navigate = useNavigate();

    const [feed, setFeed] = useState<any>({});
    const [newTaskResult, setNewTaskResult] = useState<TaskResultItem | null>(null);

    const { errorMessage } = useSelector(CheckerSelectors.checkerSelectors);
    const reqData = useSelector(CheckerSelectors.submitData);
    const fieldResult = useSelector(CheckerSelectors.fieldResult(fieldItem?.id));

    const { settingsAll } = useSelector(SettingsSelectors.settingsState);
    const { settings } = settingsAll || {};
    const isTaskCreateBlocked =
        settings?.find((s) => s.code === 'is_task_create_blocked')?.setting_values?.[0]
            ?.val === 'true';
    const resultCommentRequired =
        settings?.find((s) => s.code === 'result_comment_required')?.setting_values?.[0]?.val;

    const userId = localStorage.getItem('userId');
    const { visitID, taskId } = useParams();

    const checkersVisitRes = useMemo(() => {
        if (newTaskResult?.result) {
            return newTaskResult?.result?.checkers;
        } else return feed?.result?.checkers;
    }, [feed?.result?.checkers, taskResult]);

    const dispatch = useAppDispatch();

    const handleChange = useCallback(
        (value) => {
            dispatch(CheckerAction.finishChecker({ value: value, checker_id: fieldItem?.id }));
            formMethod.setValue(fieldItem?.id, [value]);
        },
        [dispatch, fieldItem?.id, formMethod]
    );

    const handleDescription = useCallback(
        (e) => {
            dispatch(
                CheckerAction.descriptionChecker({
                    value: e.target.value,
                    checker_id: fieldItem?.id,
                })
            );
        },
        [dispatch, fieldItem]
    );

    const handleSubmitChecker = async () => {
        const resData = reqData?.filter(
            (item) => item?.check_list_params?.length > 0 && item?.id === fieldItem?.id
        );

        if (!!resData?.length && (visitID || taskId)) {
            for (let checker of resData) {
                dispatch(
                    CheckerThunk.fetchCheckListSubmit({
                        check_list_id: checker?.id,
                        object_id: visitID ? visitID : taskId,
                        user_id: userId,
                        result: checker?.results?.[0],
                        comment: checker?.comment,
                        data: { result: checker?.check_list_params },
                        source: visitID ? 'sfa' : 'crowd',
                    })
                );

                if (!!visit?.id) {
                    if (!!checkersVisitRes?.length) {
                        dispatch(
                            FeedsThunks.fetchUpdateFeed({
                                req: {
                                    result: {
                                        ...visit?.result,
                                        checkers: [
                                            ...checkersVisitRes,
                                            {
                                                result: checker?.results?.[0],
                                                name: checker?.name,
                                                description: checker?.description,
                                            },
                                        ],
                                    },
                                },
                                visit_id: visit?.id,
                                visitUpdateStatus: true,
                            })
                        );
                    } else if (!checkersVisitRes?.length) {
                        dispatch(
                            FeedsThunks.fetchUpdateFeed({
                                req: {
                                    result: {
                                        ...visit?.result,
                                        checkers: [
                                            {
                                                result: checker?.results?.[0],
                                                name: checker?.name,
                                                description: checker?.description,
                                            },
                                        ],
                                    },
                                },
                                visit_id: visit?.id,
                                visitUpdateStatus: true,
                            })
                        );
                        if (visitID || visit?.id) {
                            await fetchFeed(visitID ?? visit?.id).then((res) => {
                                setFeed(res.feed);
                            });
                        }
                    }
                }

                if (!!taskResult?.id) {
                    updateTaskResult(taskResult.id, {
                        ...taskResult,
                        report: {
                            checkers: [
                                ...(checkersVisitRes || []),
                                {
                                    result: checker?.results?.[0],
                                    name: checker?.name,
                                    description: checker?.description,
                                },
                            ],
                        },
                    }).then((res) => {
                        if (res) {
                            const { task_result = {} } = res || {};
                            setNewTaskResult(task_result);
                            channel.postMessage({
                                visit: { id: taskId, status: task_result?.status },
                            });
                        }
                    });
                }
            }
        }
    };

    const { watch } = formMethod || {};
    const [resultAnswer] = watch(`${fieldItem?.id}`) || [];

    const handleReset = () => {
        formMethod?.reset();
    };

    const handleTaskNew = (id) => {
        navigate(`/feed-task/create/${visitID}`, { state: { check_result_id: id } });
    };

    return (
        <div>
            <span className="text-md">{fieldItem?.description}</span>
            <div className={'p-3 border-solid border-2 rounded border-black mt-4 mb-3'}>
                {!!fieldItem?.check_list_params?.length && (
                    <div className="mb-3">
                        <span className="text-sm font-bold">
                            Ответ по результату чек-листа (итог)
                            <span className="text-red">*</span>
                        </span>
                        <Controller
                            name={fieldItem?.id}
                            control={formMethod?.control}
                            defaultValue=""
                            rules={{
                                required: !fieldResult?.length && 'Поле обязательно !',
                            }}
                            render={({ field, fieldState }) => (
                                <>
                                    <div className="flex">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            {!!fieldResult?.length
                                                ? fieldItem?.results?.map((i) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        key={i}
                                                        onChange={() => {
                                                            formMethod.trigger();
                                                            handleChange(i);
                                                        }}
                                                        value={i}
                                                        control={
                                                            <Radio
                                                                key={fieldResult?.[0]?.result}
                                                                checked={
                                                                    fieldResult?.[0]?.result === i
                                                                }
                                                                size="small"
                                                            />
                                                        }
                                                        disabled={!!fieldResult?.length}
                                                        label={i}
                                                    />
                                                ))
                                                : fieldItem?.results?.map((i) => (
                                                    <FormControlLabel
                                                        {...field}
                                                        key={i}
                                                        onChange={() => {
                                                            formMethod.trigger();
                                                            handleChange(i);
                                                        }}
                                                        value={i}
                                                        control={<Radio size="small" />}
                                                        label={i}
                                                    />
                                                ))}
                                        </RadioGroup>
                                    </div>
                                    {!!fieldState?.error?.message?.length && (
                                        <div className="text-red mt-3">
                                            {fieldState?.error?.message}
                                        </div>
                                    )}
                                </>
                            )}
                        />
                    </div>
                )}
                <div className="text-sm font-bold">{t('feed.commentOnResult')}</div>
                <Controller
                    name={`${fieldItem?.id}-comment`}
                    control={formMethod?.control}
                    defaultValue=""
                    rules={{
                        required: resultCommentRequired && resultCommentRequired === resultAnswer
                            ? t('messages.fieldRequired')
                            : false,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextareaAutosize
                            onChange={(e) => {
                                field.onChange(e);
                                handleDescription(e)
                            }
                            }
                            id="outlined-multiline-flexible"
                            placeholder={!!fieldResult?.length ? fieldResult?.[0]?.comment : ''}
                            disabled={!!fieldResult?.length}
                            minRows={1}
                            variant="outlined"
                            error={error}
                        />
                    )
                    }
                >

                </Controller>
            </div>
            <FormProvider {...formMethod}>
                <div className="pl-3.5 mt-3">
                    {fieldItem?.check_list_params?.map((item) => checkFields(item, fieldItem?.id))}
                </div>
            </FormProvider>
            {errorMessage?.length > 0 && (
                <h3 className="text-red mb-1.5">Ошибка: {errorMessage}</h3>
            )}
            <Stack className="mb-3" spacing={2} direction="row">
                <Button disabled={!!fieldResult?.length} onClick={handleReset} variant="text">
                    Сбросить
                </Button>
                <Button
                    disabled={!!fieldResult?.length}
                    onClick={formMethod?.handleSubmit(handleSubmitChecker)}
                    variant="contained"
                >
                    Сохранить
                </Button>
            </Stack>
            {!isTaskCreateBlocked && fieldResult?.length > 0 &&
                <div className="mb-3">
                    <Button
                        variant='contained'
                        onClick={() => { handleTaskNew(fieldItem?.id) }}
                    >
                        {t('tasks.createNew')}
                    </Button>
                </div>}
        </div>
    );
};

export default CheckersForms;
